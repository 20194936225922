<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="User Permissions"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('add')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.name" :rules="[validate_rules.required]" label="Name *"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.sort" :rules="[validate_rules.required,validate_rules.number]" label="Sort *"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('update')" color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
      <v-row justify="center">
        <v-col cols="10">
          <v-toolbar class="search-tools pa-0 mb-4" flat>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="5" sm="5">
              <v-text-field
                label="Search"
                v-model="search"
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
              /> 
            </v-col>
          </v-toolbar>
          <v-data-table 
              :items="allUserPermissions" 
              :headers="headers"
              :search="search"
              sort-by="sort"
              :sort-asc="true"
          >
            <template #item.updatedAt="{value}">
                  <span>{{moment(value).format('DD-MM-YYYY HH:MM:SS')}}</span>
            </template>
            <template #item.actions="{item}">
                <v-btn 
                   v-if="canDo && (canDo.can.includes('detail') || canDo.can.includes('update'))"
                  class="ma-1" outlined color="primary" icon tile small @click="editItem(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn 
                  v-if="canDo && canDo.can.includes('delete')"
                  class="ma-1" outlined color="red" icon tile small @click="deleteItem(item)">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'UserPermissions',
  components: {
    pageHeading
  },
  data: () => ({
    dialog: false,
    search: '',
    editedIndex: -1,
    editedItem: {
      id: -1,
      name: '',
      sort: 0
    },
    defaultItem: {
      id: -1,
      name: '',
      sort: 0
    },
    form_valid : false,
    validate_rules : {
      required: v => !!v || 'This field is required',
      number: v => parseInt(v) > 0 || 'Please fill sorting number.',
    }
  }),
  computed: {
    ...mapGetters(['canDo','allUserPermissions']),
    headers(){
      var columns = [
        {text: "ID", value: 'id'},
        {text: "Name", value: 'name'},
        {text: "Sort", value: 'sort'},
        {text: "Last Update", value: 'updatedAt'},
        {text: "Actions", value: 'actions' , sortable: false},
      ];
      if(this.canDo && (this.canDo.can.includes('detail') || this.canDo.can.includes('update') || this.canDo.can.includes('delete'))){
        return columns;
      } else {
        return columns.filter(column => column.value != 'actions');
      }
    },
    isEdit () {
      return this.editedIndex > -1
    },
    formTitle () {
        return this.editedIndex === -1 ? 'New User Permission' : 'Edit User Permission'
    },
  },
  methods: {
    ...mapActions(['genCan','getUserPermissions','updateUserPermission','createUserPermission','deleteUserPermission']),

    addItem(){
      this.dialog = true;
    },
    
    editItem (item) {
      this.editedIndex = this.allUserPermissions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.password = ''
      this.dialog = true
    },

    deleteItem (item) {
        confirm('Are you sure you want to delete this user permission?') && this.deleteUserPermission(item);
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },

    save () {
      if(this.form_valid){
        if (this.editedIndex > -1) {
          this.updateUserPermission(this.editedItem);
        } else {
          this.createUserPermission(this.editedItem);
        }
        this.close()
      }
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  created() {
    this.genCan();
    this.getUserPermissions();
  }
};
</script>